<template>
    <SiteHeader />
    <main>
      <router-view></router-view>
    </main>
    <footer>3</footer>
</template>

<script>
import SiteHeader from './components/header/index.vue'
export default {
  name: 'App',
  components: {
    SiteHeader
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="less">


body{
  margin: 0;
  padding: 0;
}
/* 基础样式设置，让子元素继承 */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
 