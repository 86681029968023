<template>
  <div>首页内容</div>
</template>

<script>

export default {
  name: 'App',
  components: {
 
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="less">



</style>
 