import { createRouter, createWebHistory } from 'vue-router'
// 导入你的视图组件
import HomeView from '../home.vue'

// 定义路由配置
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // 路由懒加载
    component: () => import('../AboutView.vue')
  }
]

// 创建路由实例
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router