<template>
    <nav class="navigation">
      <ul>
        <li v-for="(item, index) in navItems" :key="index">
          <a :href="item.href"><i>{{ item.icon }}</i> <span>{{ item.text }}</span></a>
        </li>
        <li class="login-button">
          <a href="#">登录</a>
        </li>
      </ul>
    </nav>
  </template>
  
  <script>
  export default {
    name: 'headerIndex',
    data() {
      return {
        navItems: [
          { icon: '🏡', text: '首页', href: 'Home' },
          { icon: '💖', text: '家', href: 'About' },
          { icon: '🏖️', text: '随笔', href: '#' },
          { icon: '📒', text: '记录', href: '#' },
          { icon: '📸', text: '相册', href: '#' },
          { icon: '🧰', text: '百宝箱', href: '#' },
          { icon: '📪', text: '留言', href: '#' },
          { icon: '💬', text: '联系我', href: '#' }
        ]
      }
    }
  }
  </script>
  
  <style lang="less">
  @font-face {
    font-family: 'MyCustomFont';
    src: url('../../assets/fonts/Crostan-Bold-BF67493005064f1.ttf') format('truetype');
  }
  


  
  /* 导航栏基础样式 */
  .navigation {
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    /* height: 60px; */
  }
  
  /* 导航列表样式 */
  .navigation ul {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
  
    margin: 0;
    margin-right: 25px;
    padding: 0%;
  
  }
  
  .navigation ul li{
    list-style-type: none;
  }
  
  /* 导航链接样式 */
  .navigation a {
    color: inherit;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
    height: 60px;
    line-height: 60px;
    i{
      font-style: normal;
    }
    span{
      font-family: 'MyCustomFont', sans-serif;
    }
  }
  
  .navigation a:hover {
    color: #42b983;
  }
  
  /* 登录按钮样式 */
  .login-button {
    background-color: #ff8cb0;
    border-radius: 50%;
    width: 40px;
    height: 40px ;
    margin-top: 10px;
    font-size: 15px;
    a{
      height: 40px !important;
      line-height: 40px;
    }
  }
  </style>
   